import React from 'react';

const Footer = () => {
    return (
        <div className="header-footer">
            <div className="header-footer-center">
                <p>
                    © COPYRIGHT TRAIL MUCO - PAYS DE VITRÉ. TOUS DROITS
                    RÉSERVÉS.
                </p>
                <p>
                    Design et développement bénévole par{' '}
                    <a
                        href="https://delpierre-fabien.fr/"
                        target="_blank"
                        rel="noreferrer"
                    >
                        Delpierre Fabien
                    </a>
                </p>
                <p>Dernière mise à jour: le 03/02/2025</p>
            </div>
        </div>
    );
};

export default Footer;
